<style lang="less" scoped>
.aboutUs {
  width: 100%;
  background: #070911;
}
.aboutUs-details {
  width: 76.7%;
  height: 100%;
  max-width: 1104px;
  margin: 0 auto;
  padding: 196px 0;
  color: rgba(222, 230, 255, 1);
  text-align: center;
}
</style>

<template>
  <div class="aboutUs">
    <div class="aboutUs-details">
      <h1>{{ title }}</h1>
      <h5>发布于： {{modified.split(' ')[0]}}</h5>
      <p v-html="article"></p>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';
export default {
  name: 'aboutUs-details',
  data () {
    return {
      title: '',
      article: '',
      modified: ''
    }
  },
  created () {
    this.$api.getArticle({
      slug: this.$route.params.slug
    }).then(res => {
      this.title = res.title;
      this.article = res.content;
      this.modified = res.modified;
    })
  },
  mounted () {
  },
  methods: {
  },
  components: {
    CommonActivies,
    CommonFooter
  },
}
</script>
